'use client';
// TODO this module has direct connection to many other modules, which is antipattern
// let's refactor it

import {
  CosmicHelperWrapper,
  hasNoPadding,
  SpacingWrapper,
} from '@finn/platform-modules';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { Brand } from '@finn/ua-vehicle';
import { ErrorBoundary } from '@finn/ui-components';
// TODO delete this import after refactoring PageBodyBuilder
import { CosmicModuleType, ModuleType } from '@finn/ui-cosmic';
import React, { memo, ReactNode } from 'react';

import HeadTags from '~/components/HeadTags';
import { PageDataProvider } from '~/contexts/PageData';
import Page from '~/layouts/Default';
import { BasePageData } from '~/types/cosmicModules';
import { Review } from '~/types/reviews';
import CosmicModule from '~/ui-modules/CosmicModule';
import { buildFAQJsonLdAsStrForPage } from '~/utils/html';

import { StickyNavContent } from '../StickyNav/types';

type Props = {
  data: BasePageData;
  reviews?: Review[];
  brands?: Brand[];
  children?: ReactNode;
  stickyNavContent?: StickyNavContent;
  preview?: boolean;
  renderMetaContent?: () => ReactNode;
  hasNoPaddingTop?: boolean;
  jsonLdContentArr?: string[];
  fixedCanonicalUrl?: string;
  stickyHeader?: boolean;
  isBlog?: boolean;
};

const isSticky = (type: CosmicModuleType): boolean => {
  return type === CosmicModuleType.WEB_MODULE_HERO_STICKY_NAVS;
};

const PageBodyBuilder: React.FunctionComponent<Props> = ({
  data,
  reviews,
  brands,
  children,
  stickyNavContent,
  preview,
  renderMetaContent,
  hasNoPaddingTop,
  jsonLdContentArr = [],
  fixedCanonicalUrl,
  stickyHeader = false,
  isBlog,
}) => {
  const { modules, seo_header } = data.metadata;
  const faqJsonldAsString = buildFAQJsonLdAsStrForPage(modules);
  if (faqJsonldAsString) {
    jsonLdContentArr.push(faqJsonldAsString);
  }

  const isExpStickyNavbar = useIsABVariant(Features.ExpStickyNavbar);
  let hasStickyHeader = stickyHeader;
  if (!isExpStickyNavbar) {
    hasStickyHeader = false;
  }

  return (
    <>
      <PageDataProvider reviews={reviews} brands={brands}>
        <CosmicHelperWrapper data={data} preview={preview} />
        <Page data={data} stickyHeader={hasStickyHeader}>
          <HeadTags
            seoHeader={seo_header}
            jsonLdContent={jsonLdContentArr}
            canonicalUrl={fixedCanonicalUrl}
          />
          {renderMetaContent?.()}
          {modules.map((module: ModuleType) => {
            if (
              // Our sticky navbar experiment must override sticky CTA panels
              module.type === CosmicModuleType.WEB_MODULE_STICKY_CTA_PANEL &&
              hasStickyHeader
            ) {
              return null;
            }

            return (
              <ErrorBoundary key={module.id || module.type}>
                {isSticky(module.type) ? (
                  <CosmicModule
                    data={module}
                    stickyNavContent={
                      module.type ===
                      CosmicModuleType.WEB_MODULE_HERO_STICKY_NAVS
                        ? stickyNavContent
                        : undefined
                    }
                  />
                ) : (
                  <SpacingWrapper
                    noPaddingTop={hasNoPaddingTop || hasNoPadding(module.type)}
                    html_id={module.metadata?.html_id}
                    isBlog
                  >
                    <CosmicModule data={module} isBlog={isBlog} />
                  </SpacingWrapper>
                )}
              </ErrorBoundary>
            );
          })}
          {children}
        </Page>
      </PageDataProvider>
    </>
  );
};

export default memo(PageBodyBuilder);
